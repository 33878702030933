import React, {Component, useState} from 'react';
import CONFIG from './../SYSTEM/Config.js'
import { sendPostRequest } from "./../APP/HELPERS/requests"
import Cookies from 'universal-cookie';

import { Map, Polygon, Rectangle, YMaps } from 'react-yandex-maps';

/// REDUX ///
import * as ReduxActions from './../REDUX/functions.js'
import { bindActionCreators } from 'redux'
import {connect} from 'react-redux'


import {setPolygonsData} from "./../REDUX/functions.js";
import moment from "moment";

const cookies = new Cookies();

function mapStateToProps(state)
{
    return {
        CART: state.CART,
        REGION: state.REGION,
        GPS: state.GPS,
        IS_TESTER: state.IS_TESTER,
        ADRESS: state.ADRESS,

    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

class DataFetch extends Component {

	constructor(props)
    {
        super(props);

        this.state = {
        	TOKEN: false,
            USER: false,
        }
    }


    componentDidMount = async () =>
    {
        this.getAuth();

        let ecopoll = cookies.get('ecopoll');
        if (ecopoll !== null) {
            this.getAuthByCookie(ecopoll);
        }
    }

    getAuth = async () =>
    {
        let USER = await localStorage.getItem('USER');
        if (USER !== null) {
            this.props.doAuth(JSON.parse(USER));
            this.setState({USER: USER});
            return true;
        } else {
            return false;
        }
    }

    getAuthByCookie = async(ecopoll) =>
    {
        try {
            let SEND_DATA = {
                action:     'workerPollUserByCookie',
                ecopoll:    ecopoll,
                token:      this.state.TOKEN
            };
            let data = await sendPostRequest(SEND_DATA,CONFIG.API_GATE);
            if (data !== false) {
                cookies.set('ecopoll', data.user.ecopoll, { path: '/', time: data.user.time });
                this.props.doAuth(data.user);
                this.setState({USER: data.user});
            }
            return true;
        } catch (error) {
        }
    }

    get(key){
       var p = window.location.search;
        p = p.match(new RegExp(key + '=([^&=]+)'));
        return p ? p[1] : false;
    }


	render() {
		return (
            <></>
        );
	}

}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(DataFetch)
