export const openBadRatingModal = (data) =>
{
	return {
		type: 'BAD_RATING_MODAL',
		data: data
	}
}

export const setUseablePeriods = (data) =>
{
	return {
		type: 'SET_USEABLE_PERIODS',
		data: data
	}
}

export const setPolygonsData = (data) =>
{
	return {
		type: 'SET_POLYGONS_DATA',
		data: data
	}
}

export const setAddressWithAddition = (data) =>
{
	return {
		type: 'ADDRESS_WITH_ADDITION',
		data: data
	}
}



export const openDeliveryAddressMixedModal = (data) =>
{
	return {
		type: 'OPEN_DEL_ADRESS_MIXED_MODAL',
		data: data
	}
}

export const setAddressPoint = data => {
	return {
		type: 'SET_ADDRESS_POINT',
		data: data
	}
}

export const openRegionSelector = (data) =>
{
	return {
		type: 'OPEN_REGION_SELECTOR',
		data: data
	}
}
export const openRegionSelectorCities = (data) =>
{
	return {
		type: 'OPEN_CITIES_SELECTOR',
		data: data
	}
}


export const setAdressAndGpsAndRegion = (data) =>
{
	return {
		type: 'SET_ADRESS_GPS_ADRESS_REGION',
		data: data
	}
}

export const openDeliveryAddressModal = (data) =>
{
	return {
		type: 'SHOW_DELIVERY_ADDRESS_MODAL',
		data: data
	}
}

export const openDeliveryPaymentModal = (data) =>
{
	return {
		type: 'SHOW_DELIVERY_PAYMENT_MODAL',
		data: data
	}
}

export const openForcedAddressSelectModal = (data) =>
{
	return {
		type: 'SHOW_FORCED_ADDRESS_SELECT_MODAL',
		data: data
	}
}

export const startAddingProductsToExistingOrder = (data) =>
{
	return {
		type: 'START_EXISTING_ORDER',
		data: data
	}
}

export const limitNavs = (data) =>
{
	return {
		type: 'LIMIT_NAVS',
		data: data
	}
}

export const setKHId = (data) =>
{
	return {
		type: 'SET_KH_ID',
		data: data
	}
}

export const setClickId = (data) => {
	return {
		type: 'SET_CLICK_ID',
		data: data
	}
}

export const setFavs = (data) => {
	return {
		type: 'SET_FAVS',
		data: data
	}
}

export const addToFavs = (data) => {
	return {
		type: 'ADD_TO_FAVS',
		data,
	}
}

export const removeFromFavs = (data) => {
	return {
		type: 'REMOVE_FROM_FAVS',
		data: data
	}
}

export const openNotFounModal = (data) =>
{
	return {
		type: 'OPEN_NOT_FOUND_MODAL',
		data: data
	}
}

export const setRefSite = (data) => {
	return {
		type: 'SET_REF_SITE',
		data: data
	}
}

export const showLocationAlert = (data) =>
{
	return {
		type: 'SHOW_LOCATION_ALERT',
		data: data
	}
}

export const setRefString = (data) => {
	return {
		type: 'SET_REF_STRING',
		data: data
	}
}

export const recordPostData = (data) => {
	return {
		type: 'RECORD_POST_DATA',
		data: data
	}
}

export const setRefCode = (data) => {
	return {
		type: 'SET_REF_CODE',
		data: data
	}
}

export const changeRegion = (data) => {
	return {
		type: 'CHANGE_REGION',
		data: data
	}
}

export const setPostalDaysAndPrice = (data) => {
	return {
		type: 'SET_POSTAL_DAYS_AND_PRICE',
		data: data
	}
}

export const resetProducts = () => {
	return {
		type: 'RESET_PRODUCTS'
	}
}

export const showRegionHelper = (data) => {
	return {
		type: 'SHOW_REGION_HELPER',
		data: data
	}
}

export const updateAvatar = (data) => {
	return {
		type: 'UPDATE_AVATAR',
		data: data
	}
}

export const setMAP = (data) => {
	return {
		type: 'SET_MAP',
		data: data
	}
}

export const emptyCartModal = (data) => {
	return {
		type: 'EMPTY_CART_MODAL',
		data: data
	}
}

export const setStartUpData = (data) => {
	return {
		type: 'SET_START_UP_DATA',
		data: data
	}
}

export const setGrouped = (data) => {
	return {
		type: 'SET_GROUPED',
		data: data
	}
}

export const openProductModal = (id,product) => {
	return {
		type: 'OPEN_PRODUCT_MODAL',
		data: id,
		product,
	}
}

export const metrikaProductModal = (url,cart) => {
	return {
		type: 'METRIKA_PRODUCT_MODAL',
		url,
		cart
	}
}

export const addToCart = (data, from = window.location.href) => {
 	return {
		type: 'ADD_TO_CART',
		data: data,
		from
	};
}

export const openCart = (data) => {
 	return {
		type: 'OPEN_CART',
		data: data
	};
}

export const openOrder = (data) => {
 	return {
		type: 'OPEN_ORDER',
		data: data
	};
}

export const addToCartAmount = (data, amount) => {
 	return {
		type: 'ADD_TO_CART_AMOUNT',
		data: data,
		amount: amount
	};
}

export const emptyCart = (data) => {
 	return {
		type: 'EMPTY_CART',
		data: data
	};
}

export const removeFromCart = (data, from = window.location.href) => {
 	return {
		type: 'REMOVE_FROM_CART',
		data: data,
		from,
	};
}

export const plusCart = (data, from = window.location.href) => {
 	return {
		type: 'PLUS_CART',
		data: data,
		from
	};
}

export const minusCart = (data, from = window.location.href) => {
 	return {
		type: 'MINUS_CART',
		data: data,
		from,
	};
}

export const setCart = (data) => {
 	return {
		type: 'SET_CART',
		data: data
	};
}

export const setLoadOrder = (data) => {
	return {
		type: 'LOAD_ORDER',
		data: data
	};
}

export const recordCart = (data) => {
 	return {
		type: 'SET_CART',
		data: data
	};
}

export const openLK = (data) => {
 	return {
		type: 'OPEN_LK',
		data: data
	};
}

export const closeAllModals = (data) => {
	return {
		type: 'CLOSE_ALL_MODALS',
		data: data
	};
}

export const doAuth = (data) => {
	return {
		type: 'DO_AUTH',
		data: data
	};
}

export const logOut = () => {
	return {
		type: 'DO_LOG_OUT'
	};
}

export const setBonusesAmount = (data) => {
	return {
		type: 'SET_BONUSES_AMOUNT',
		data: data
	};
}

export const doPay = (data) => {
	return {
		type: 'DO_PAY',
		data: data
	};
}

export const addCardModal = (data) => {
	return {
		type: 'ADD_CARD',
		data: data
	};
}

export const doSearch = (data) => {
	return {
		type: 'DO_SEARCH',
		data: data
	};
}

export const toggleNav = (data) => {
	return {
		type: 'TOGGLE_NAV',
		data: data
	};
}

export const toggleSearch = (data) => {
	return {
		type: 'TOGGLE_SEARCH',
		data: data
	};
}

export const closeNav = () => {
	return {
		type: 'CLOSE_NAV'
	};
}

export const setPreparedCats = (data) => {
	return {
		type: 'SET_PREPARED_CATS',
		data: data
	};
}

export const setQuestProducts = (data) => {
	return {
		type: 'SET_QUEST_PRODUCTS',
		data: data
	};
}

export const setIsTester = value => {
	return {
		type: 'SET_IS_TESTER',
		value
	}
}

export const dontChangeURL = data => {
	return {
		type: 'DONT_CHANGE_URL',
		data
	}
}

export const scroll = data => {
	return {
		type: 'SCROLL',
		data
	}
}

export const setPriceOfDeliveryPerPrice = data => {
	return {
		type: 'SET_PRICE_OF_DELIVERY_PER_PRICE',
		data
	}
}