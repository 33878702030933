/// CONFIG ///

const CONFIG = {
    APP_TITLE:      'Ecomarket',
    API_IP: 		'80.87.194.133',
    API_DOMAIN: 	'https://api.ecomarket.ru/',
    API_C_DOMAIN: 	'https://api.ecomarket.ru',
    API_GATE: 		'https://api.ecomarket.ru/api.php',
    PHONE: 			'8(495)4450505',
    MINIMAL: 		 2000,
    CP_PUBLIC_ID: 	'pk_e799ee8da07f1b383dbb4ffd12e3d',
    INSTAGRAM_URL:  'https://www.instagram.com/Ecomarket.ru/',
    GEOCODER_KEY:   '7020eded-f424-41c9-a1ee-3b900407ec1d',
    USE_REGION_DELIVERY: false
};

window.config1 = CONFIG

export default CONFIG;

