import CONFIG from "../../SYSTEM/Config";
import moment from "moment";
const md5 = require('md5')

export const sendPostRequest = async (data,gateway=CONFIG.API_GATE) =>
{
    const response = await fetch( 
        gateway, 
        {
            credentials: 'include',
            method: "POST",
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                'Accept': 'application/json'
            },
        }
    );

    const responseJson = await response.json();

    if (responseJson.status.result === 'SUCCESS') {
        return responseJson.data;
    } else {
        return false;
    }
}

export function createHash (number) {
    const str = moment().format('MM')+number+moment().format('DD')
    return md5(str)
}