import React, {Component} from 'react';
import {Switch, Route, Link} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import CONFIG from '../../SYSTEM/Config.js'
import {createHash, sendPostRequest} from "../HELPERS/requests"
import InputMask from "react-input-mask";

/// REDUX ///
import * as ReduxActions from '../../REDUX/functions.js';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import moment from "moment";

function mapStateToProps(state) {
    return {
        TOKEN: state.TOKEN,
        ADRESS: state.ADRESS,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}


class Login extends Component {

    constructor(props) {
        super(props);
        this.state = {
            PHONE: '+7-___-___-__-__',
            CODE1: '',
            CODE2: '',
            CODE3: '',
            CODE4: '',
            PASSWORD: '',
            VIEW: 'REGISTER',
            SENT: false,
            TOKEN: this.props.TOKEN,
            SECONDS: 60,
            SECONDS_LIMIT: true,
            WHATSAPP_BUTTON_TEXT: "Отправить мне сообщение",
            WHATSAPP_BUTTON_COLOR: "orange-button",
            RENDER_WAHATSAPP_CODE: false,
            agreed: false,
            WACode: false,
        }
    }

    recordPhone = (phone) => {
        this.setState({PHONE: phone.target.value});
    }

    recordCode = (code) => {
        this.setState({CODE: code.target.value});
    }
    recordCode1 = (code) => {
        if (code.target.value.toString().replace(/\D+/g, '').length > 0) {
            this.setState({CODE1: code.target.value});
            this.codeInput2.focus();
        } else {
            this.setState({CODE1: ''});
        }
    }
    recordCode2 = (code) => {
        if (code.target.value.toString().replace(/\D+/g, '').length > 0) {
            this.setState({CODE2: code.target.value});
            this.codeInput3.focus();
        } else {
            this.setState({CODE2: ''});
        }
    }
    recordCode3 = (code) => {
        if (code.target.value.toString().replace(/\D+/g, '').length > 0) {
            this.setState({CODE3: code.target.value});
            this.codeInput4.focus();
        } else {
            this.setState({CODE3: ''});
        }
    }
    recordCode4 = (code) => {
        if (code.target.value.toString().replace(/\D+/g, '').length > 0) {
            this.setState({CODE4: code.target.value});
        } else {
            this.setState({CODE4: ''});
        }
    }

    recordPassword = (password) => {
        this.setState({PASSWORD: password.target.value});
    }

    doConfirm = async () => {
        try {
            let SEND_DATA = {
                action: 'workerPollConfirmCode',
                phone: this.state.PHONE,
                code: this.state.CODE1 + '' + this.state.CODE2 + '' + this.state.CODE3 + '' + this.state.CODE4
            };

            let data = await sendPostRequest(SEND_DATA, CONFIG.API_GATE);
            if (data !== false) {
                this.props.doAuth(data);
            } else {
                this.setState({CODE_ERROR: true, CODE1: '', CODE2: '', CODE3: '', CODE4: ''});
            }
        } catch (error) {
            console.warn('PROBLEM WHILE LOGGING IN');
        }
    }

    doLogin = async () => {
        try {
            let SEND_DATA = {
                action: 'doLogin',
                token: this.state.TOKEN,
                phone: this.state.PHONE,
                password: this.state.PASSWORD,
                source: 'web'
            };

            let data = await sendPostRequest(SEND_DATA, CONFIG.API_GATE);
            if (data !== false) {
                this.setState({LOGIN_ERROR: false});
                this.props.doAuth(data.user);
                this.getServerFavs(data.user);
            } else {
                this.setState({LOGIN_ERROR: true});
                this.codeInput1.focus();
            }
        } catch (error) {
            console.warn('PROBLEM WHILE LOGGING IN');
            this.setState({LOGIN_ERROR: true});
        }
    }

    getServerFavs = async (USER) => {
        try {
            let SEND_DATA = {
                action: 'getFavs',
                apikey: USER.TOKEN,
            };

            let data = await sendPostRequest(SEND_DATA, CONFIG.API_GATE);
            if (data !== false) {
                this.props.setFavs(JSON.parse(data.favs));
            }
            return true;
        } catch (error) {

        }
    }

    doRegister() {
        clearInterval(this.COUNTER);
        this.setState({VIEW: 'REGISTER'})
    }

    doGetCode = async () => {
        let SEND_DATA = {
            action: 'workerPollGetCode',
            phone: this.state.PHONE
        };

        let data = await sendPostRequest(SEND_DATA, CONFIG.API_GATE);

        if (data !== false) {
            this.setState({VIEW: 'CONFIRM', SENT: true, SECONDS: 30})
        }
    }


    countDown() {
        let COMPONENT = this;
        this.COUNTER = setInterval(function () {
            let SECONDS = COMPONENT.state.SECONDS;

            if (SECONDS > 0) {
                SECONDS--;
                COMPONENT.setState({SECONDS: SECONDS});
            } else {
                clearInterval(COMPONENT.COUNTER);
                COMPONENT.setState({SECONDS: '00', SENT: false});
            }
            if (SECONDS == 0) {
                COMPONENT.setState({SECONDS_LIMIT: false})
            }
        }, 1000);
    }

    handleLogin = (e) => {
        if (e.key === 'Enter') {
            this.doLogin()
        }
    }

    handleRegister = (e) => {
        if (e.key === 'Enter') {
            this.doGetCode()
        }
    }

    handleConfirm = (e) => {
        if (e.key === 'Enter') {
            this.doConfirm()
        }
    }

    getLoginButton() {
        let phoneNumber = this.state.PHONE.toString().replace(/\D+/g, '');

        if ((phoneNumber.length == 11) && (this.state.PASSWORD.length > 3)) {
            return (
                <button className="orange-button" onClick={() => this.doLogin()}>
                    Войти
                </button>
            );
        } else {
            return (
                <button className="orange-button inactive-btn">
                    Войти
                </button>
            );
        }
    }

    renderLoginError() {
        if (this.state.LOGIN_ERROR) {
            return (
                <div className="login_error">
                    Данные для входа не подходят. Проверьте, указанные данные!
                </div>
            );
        }
    }

    renderLogin() {
        return (
            <div className="ajax unform">
                <div className="icon-round">
                    <img src={'/system_images/login_ico.png'}/>
                </div>

                <div className="modal-intro-head">
                    Добро пожаловать в Экомаркет!
                </div>
                <div className="modal-intro-text">
                    Укажите ваш номер телефона и пароль для входа в личный кабинет
                </div>

                <div className="form-fields">
                    <InputMask onKeyDown={this.handleLogin} placeholder="Ваш телефон" type="tel" className="phone"
                               mask="+7-999-999-99-99" onChange={(text) => this.recordPhone(text)}/>
                </div>

                <div className="form-fields">
                    <input onKeyDown={this.handleLogin} type="password" name="password" placeholder="Ваш пароль"
                           onChange={(text) => this.recordPassword(text)} required=""/>
                </div>

                <div className="text-right">
                    <a className="dont-remember" onClick={() => this.doRegister()}>Не помню пароль</a>
                </div>

                <div className="success-message">
                </div>


                {this.renderLoginError()}
                <div className="text-center">
                    {this.getLoginButton()}
                </div>

                <div className="text-center">
                    <a className="register-button" onClick={() => this.doRegister()}>
                        Регистрация
                    </a>
                </div>
            </div>
        );
    }

    getRegisterButton() {
        let phoneNumber = this.state.PHONE.toString().replace(/\D+/g, '');

        if (phoneNumber.length === 11) {
            return (
                <button className="orange-button" onClick={() => this.doGetCode()}>
                    Позвонить мне
                </button>
            );
        } else {
            return (
                <button className="orange-button inactive-btn">
                    Позвонить мне
                </button>
            );
        }
    }

    renderRegister() {
        let phoneNumber = this.state.PHONE.toString().replace(/\D+/g, '');

        return (
            <div className="ajax unform">
                <div className="register_header">
                    Введите номер телефона
                </div>

                <div className="form-fields">
                    <InputMask autoFocus value={this.state.PHONE} onKeyDown={this.handleRegister} placeholder="Ваш телефон"
                               type="tel" className="phone"
                               mask="+7-999-999-99-99" onChange={(text) => this.recordPhone(text)}/>
                </div>

                <div className="register_after_text">
                    На указанный номер вам поступит звонок.<br/>Отвечать не нужно.
                </div>

                <div style={{marginTop: '40px'}} className="text-center">
                    {this.getRegisterButton()}
                </div>
            </div>
        );
    }

    renderConfirmButton() {
        if ((this.state.CODE1.toString().replace(/\D+/g, '').length == 0) || (this.state.CODE2.toString().replace(/\D+/g, '').length == 0) || (this.state.CODE3.toString().replace(/\D+/g, '').length == 0) || (this.state.CODE4.toString().replace(/\D+/g, '').length == 0)) {
            return <button className="orange-button inactive-btn">Войти</button>
        } else {
            return <button className="orange-button" onClick={() => this.doConfirm()}>Войти</button>
        }
    }

    renderWhatsAppButton = () => {

        if ((this.state.CODE1.toString().replace(/\D+/g, '').length == 0) || (this.state.CODE2.toString().replace(/\D+/g, '').length == 0) || (this.state.CODE3.toString().replace(/\D+/g, '').length == 0) || (this.state.CODE4.toString().replace(/\D+/g, '').length == 0)) {
            return <button className={this.state.WHATSAPP_BUTTON_COLOR}
                           onClick={() => this.doWhatsaapConfirm()}>{this.state.WHATSAPP_BUTTON_TEXT}</button>
        } else {

            return <button className="orange-button" onClick={() => this.doConfirm()}>Подтвердить</button>
        }
    }

    doWhatsaapConfirm = async () => {
        /*        if (!this.state.RENDER_WAHATSAPP_CODE) {*/
        let SEND_DATA = {
            action: 'workerPollGetCode',
            phone: this.state.PHONE,
           
        };

        await sendPostRequest(SEND_DATA, CONFIG.API_GATE);

        this.setState({
            WHATSAPP_BUTTON_COLOR: "orange-button inactive-btn",
            WHATSAPP_BUTTON_TEXT: "Подтвердить",
            RENDER_WAHATSAPP_CODE: true,
            SECONDS: 30
        })
        /*this.countDown();*/
        /*        } else {
                    return false
                }*/


    }


    getCodeClass = () => {
        if (this.state.CODE_ERROR) {
            return ' error_code_input'
        }

        return '';
    }

    renderCodeText = () => {
        if (this.state.CODE_ERROR) {
            return <span className="error_code_input_happend">Ой, код неверный. Попробуйте еще раз</span>
        } else {
            if (this.state.SECONDS_LIMIT && !this.state.WACode) {
                return <div>Впишите <span>4 последние цифры</span> номера<br/>входящего звонка. Это код авторизации
                </div>;
            } else {
                if (!this.state.RENDER_WAHATSAPP_CODE && !this.state.WACode) {
                    return <div>Звонок не поступил? Мы отправим вам <br/>сообщение в WhatsApp с 4-х значным
                        кодом <br/> авторизации. Попробуем?</div>;
                } else {
                    return <div>Мы отправили вам сообщение в WhatsApp <br/> с 4-х значным кодом. Введите его в поле ниже
                    </div>;
                }
            }

        }
    }
    renderCodeBlock = () => {
        if (this.state.RENDER_WAHATSAPP_CODE) {
            return (
                <div className="register_whatsapp">
                    <div className="enter_code_block whatsapp">
                        <input maxLength="1" ref={(input) => {
                            this.codeInput1 = input;
                        }} type="tel" className={'code_input ' + this.getCodeClass()} mask="9"
                               onChange={(text) => this.recordCode1(text)} value={this.state.CODE1}/>
                        <input maxLength="1" ref={(input) => {
                            this.codeInput2 = input;
                        }} type="tel" className={'code_input ' + this.getCodeClass()} mask="9"
                               onChange={(text) => this.recordCode2(text)} value={this.state.CODE2}/>
                        <input maxLength="1" ref={(input) => {
                            this.codeInput3 = input;
                        }} type="tel" className={'code_input ' + this.getCodeClass()} mask="9"
                               onChange={(text) => this.recordCode3(text)} value={this.state.CODE3}/>
                        <input onKeyDown={this.handleConfirm} maxLength="1" ref={(input) => {
                            this.codeInput4 = input;
                        }} type="tel" className={'code_input ' + this.getCodeClass()} mask="9"
                               onChange={(text) => this.recordCode4(text)} value={this.state.CODE4}/>
                    </div>
                    {/*                    <span className="seconds">00:{this.showMeSeconds()}</span>*/}
                </div>
            )
        }

    }

    showMeSeconds = () => {
        if (parseInt(this.state.SECONDS) < 10) {
            return '0' + parseInt(this.state.SECONDS);
        }

        return this.state.SECONDS;
    }


    renderConfirm() {

        let phoneNumber = this.state.PHONE.toString().replace(/\D+/g, '');

        return (
            <div>
                <div className="ajax unform">

                    <div className="register_header">
                        Подтвердите номер телефона
                    </div>

                    <div className="text-center">
                        <a className="ref-text-intro enter-phone-repeat" onClick={() => this.doRegister()}>
                            Ввести номер телефона заново
                        </a>
                    </div>

                    <div className="register_data_holder">
                        <div className="register_enter_code_text">
                            {this.renderCodeText()}
                        </div>
                        {this.renderCodeBlock()}
                            <div>
                                <div className="enter_code_block">
                                    <div className="enter_code_mask">
                                        +7 (XXX) XXX
                                    </div>
                                    <input maxLength="1" ref={(input) => {
                                        this.codeInput1 = input;
                                    }} type="tel" className={'code_input ' + this.getCodeClass()} mask="9"
                                           onChange={(text) => this.recordCode1(text)} value={this.state.CODE1}/>
                                    <input maxLength="1" ref={(input) => {
                                        this.codeInput2 = input;
                                    }} type="tel" className={'code_input ' + this.getCodeClass()} mask="9"
                                           onChange={(text) => this.recordCode2(text)} value={this.state.CODE2}/>
                                    <input maxLength="1" ref={(input) => {
                                        this.codeInput3 = input;
                                    }} type="tel" className={'code_input ' + this.getCodeClass()} mask="9"
                                           onChange={(text) => this.recordCode3(text)} value={this.state.CODE3}/>
                                    <input onKeyDown={this.handleConfirm} maxLength="1" ref={(input) => {
                                        this.codeInput4 = input;
                                    }} type="tel" className={'code_input ' + this.getCodeClass()} mask="9"
                                           onChange={(text) => this.recordCode4(text)} value={this.state.CODE4}/>
                                </div>
                            </div>

                       
                    </div>

                    <div className="text-center margin-top-10">
                        { this.renderConfirmButton() }
                    </div>
                </div>
            </div>
        );
    }

    render() {
        let VIEW = false;
        if (this.state.VIEW == 'LOGIN') {
            VIEW = this.renderLogin();
        } else if (this.state.VIEW == 'REGISTER') {
            VIEW = this.renderRegister();
        } else if (this.state.VIEW == 'CONFIRM') {
            VIEW = this.renderConfirm();
        }

        return (
            <div className="login_holder">
                <div className="login_block">
                    <div className="modal_content">
                        <div id="authorize" className="inside active">
                            {VIEW}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Login)