import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';

/// REDUX ///
import * as ReduxActions from './../REDUX/functions.js';
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';


/// PARTS ///

import ContentBlock from './../APP/COMPONENTS/Content.js';
import Preloader from './../APP/COMPONENTS/Preloader.js';

import moment from "moment";
import 'moment/locale/ru'

moment.locale('ru')

function mapStateToProps(state) {
    return {
        LIMIT_NAVS: state.LIMIT_NAVS,
        PRODUCTS: state.PRODUCTS,
		ADRESS: state.ADRESS
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}


class App extends Component {

	constructor(props)
    {
    	super(props);
	}

	componentDidMount()
	{

	}

	get(name){
	   if(name=(new RegExp('[?&]'+encodeURIComponent(name)+'=([^&]*)')).exec(window.location.search))
	      return decodeURIComponent(name[1]);
	}

	render() {
		return (
			<div className={"the-holder"}>
				<ContentBlock />
			</div>
		);
	}

}

export default withRouter(connect(
	mapStateToProps,
	mapDispatchToProps
)(App))
