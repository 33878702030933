import React, {Component} from 'react';
import {Switch, Route, withRouter} from 'react-router-dom';
import CONFIG from '../../SYSTEM/Config.js'
import {createHash, sendPostRequest} from "../HELPERS/requests"

/// REDUX ///
import * as ReduxActions from './../../REDUX/functions.js';
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';

import PollQuestion from './../../APP/COMPONENTS/PollQuestion'

function mapStateToProps(state, myProps) {
    return {
        USER: state.USER
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}


class Poll extends Component {

	constructor(props) {
        super(props);
        this.state = {
            LOADED: false,
            POLL: false,
            CURRENT: 0,
            ANSWERS: {},
            FINISHED: false,
            CORRECT: 0,
            INCORRECT: 0,
            STARTED: false
        }
	}

    componentDidMount = () =>
    {
        let poll = this.get('poll');

        if (poll !== undefined) {
            this.fetchPoll(poll);
        }
    }

	componentDidUpdate(prevProps) {

	}

    fetchPoll = async (poll) =>
    {
        try {
            let SEND_DATA = {
                action:         'workerPollGetPoll',
                poll:           poll,
                wp_apikey:      this.props.USER.token
            };
            let data = await sendPostRequest(SEND_DATA,CONFIG.API_GATE);
            if (data !== false) {
                if (data.answers.length) {
                    this.startFromQuestion(data)
                } else {
                    this.setState({LOADED: true, POLL: data, CURRENT: 0,ANSWERS: {}, FINISHED: false, STARTED: false})
                }
            } else {
                this.setState({LOADED: true, CURRENT: 0,ANSWERS: {}, FINISHED: false, STARTED: false})
            }
            return true;
        } catch (error) {
            this.setState({LOADED: true, CURRENT: 0,ANSWERS: {}, FINISHED: false, STARTED: false})
        }
    }

    startFromQuestion = (data) =>
    {
        let ANSWERS = {}
        let CURRENT = 0;
        let CORRECT = 0;
        let INCORRECT = 0;

        for (let i = 0; i < data.answers.length; i++) {
            let ANSWER_DATA = data.answers[i]
            ANSWERS[ANSWER_DATA.answer_index] = ANSWER_DATA
            CURRENT++          

            if (ANSWER_DATA.correct) {
                CORRECT++
            } else {
                INCORRECT++
            }
        }

        this.setState({LOADED: true, POLL: data, CURRENT: CURRENT + 1,ANSWERS: ANSWERS, FINISHED: false, STARTED: true, CORRECT: CORRECT, INCORRECT: INCORRECT},() => console.log(this.state))
    }

    startPoll = async () =>
    {
        try {
            let SEND_DATA = {
                action:         'workerPollStartPoll',
                poll:           this.state.POLL.id,
                wp_apikey:      this.props.USER.token
            };
            await sendPostRequest(SEND_DATA,CONFIG.API_GATE);
        } catch (error) {
        }

        this.setState({STARTED: true})
    }

    recordfinish = async () =>
    {
        try {
            let SEND_DATA = {
                action:         'workerPollFinishPoll',
                poll:           this.state.POLL.id,
                wp_apikey:      this.props.USER.token
            };
            await sendPostRequest(SEND_DATA,CONFIG.API_GATE);
        } catch (error) {
        }
    }

    recordAnswerOnServer = async (ANSWER_DATA) =>
    {
        try {
            let SEND_DATA = {
                action:         'workerPollRecordAnswer',
                poll:           this.state.POLL.id,
                wp_apikey:      this.props.USER.token,
                data:           ANSWER_DATA
            };
            await sendPostRequest(SEND_DATA,CONFIG.API_GATE);
        } catch (error) {
        }

        this.setState({STARTED: true})
    }

    get(key){
        var p = window.location.search;
         p = p.match(new RegExp(key + '=([^&=]+)'));
         return p ? p[1] : false;
    }

    recordAnswer = (answer) =>
    {
        let ANSWERS = {...this.state.ANSWERS}

        let ANSWER_DATA = {
            question_index: this.state.CURRENT,
            question_text: this.state.POLL.questions[this.state.CURRENT].question,
            answer_text: this.state.POLL.questions[this.state.CURRENT].options[answer - 1],
            answer_index: answer - 1,
            correct: this.state.POLL.questions[this.state.CURRENT].correct == answer ? true : false
        }

        this.recordAnswerOnServer(ANSWER_DATA)
    
        ANSWERS[this.state.CURRENT] = ANSWER_DATA

        let CORRECT = this.state.CORRECT;
        let INCORRECT = this.state.INCORRECT;

        if (this.state.POLL.questions[this.state.CURRENT].correct == answer) {
            CORRECT++;
        } else {
            INCORRECT++;
        }

        if (this.state.POLL.questions.length == this.state.CURRENT + 1) {
            this.setState({FINISHED: true, ANSWERS: ANSWERS, CORRECT: CORRECT, INCORRECT: INCORRECT})
            this.recordfinish()
        } else {
            this.setState({CURRENT: this.state.CURRENT + 1, ANSWERS: ANSWERS, CORRECT: CORRECT, INCORRECT: INCORRECT})
        }
    }

    renderStatistics = () =>
    {
        if (this.state.CORRECT > 0 || this.state.INCORRECT > 0) {
            return (
                <div className="question_statistics">
                    <span className="correct_answers">{this.state.CORRECT}</span>/<span className="incorrect_answers">{this.state.INCORRECT}</span>
                </div>
            )
        }
    }

	render() {
        if (!this.state.LOADED) {
            return <></>
        }

        if (this.state.LOADED && !this.state.POLL) {
            return (
                <div className="poll_holder">
                    <div className="bad_message">
                        К сожалению, данного опросника не существует!
                    </div>
                </div>
            );
        }

        return (
            <div className="poll_holder">
                <h1>{this.state.POLL.title}</h1>
                {this.state.FINISHED &&
                    <div className="question_holder">
                        <div className="question_block">
                            <div className="finished_poll">Спасибо за прохождение теста!</div>
                        </div>
                    </div>
                }
                {!this.state.FINISHED && 
                    <div className="current_steps">
                        {this.state.CURRENT + 1} из {this.state.POLL.questions.length}
                        {this.renderStatistics()}
                    </div>
                }
                {!this.state.STARTED && 
                    <div className="question_holder">
                        <div className="question_block">
                            <div className="poll_start_block">
                                <a className="orange-button" onClick={() => this.startPoll()}>Начать прохождение</a>
                            </div>
                        </div>
                    </div>
                }
                {
                    !this.state.FINISHED && this.state.STARTED && this.state.POLL.questions.map((que, index) => 
                        <PollQuestion
                            key={index}
                            question={que}
                            question_index={index}
                            show={index == this.state.CURRENT ? true : false}
                            recordAnswer={this.recordAnswer}
                        />
                    )
                }
            </div>
        );
	}
}

export default withRouter(connect(
	mapStateToProps,
	mapDispatchToProps
)(Poll))
