import React, {Component} from 'react';
import {Switch, Route, withRouter} from 'react-router-dom';

/// REDUX ///
import * as ReduxActions from './../../REDUX/functions.js';
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';

import Login from './../../APP/COMPONENTS/Login';
import Poll from './../../APP/COMPONENTS/Poll';

function mapStateToProps(state, myProps) {
    return {
        USER: state.USER
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}


class content extends Component {

	constructor(props) {
        super(props);
	}

	componentDidUpdate(prevProps) {

	}

	render() {
		if (!this.props.USER) {
			return <Login />
		} else {
			return (
				<div>
					<div className="navs">
						<a className="logout" onClick={() => this.props.logOut()}>Выйти</a>
					</div>
					<Poll />
				</div>
			);
		}
	}
}

export default withRouter(connect(
	mapStateToProps,
	mapDispatchToProps
)(content))
