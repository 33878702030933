import Cookies from 'universal-cookie';

import CONFIG from './../SYSTEM/Config.js'
import {sendPostRequest} from "./../APP/HELPERS/requests"
import {store} from "../SYSTEM/Router";

const cookies = new Cookies();

var initState = {
    USER: false,
};

var reducer = function (state = initState, action) {

    switch (action.type) {

        case 'DO_AUTH':
            let USER = action.data;
            cookies.set('ecopoll', USER.ecopoll, {path: '/', time: USER.time});
            localStorage.setItem('USER', JSON.stringify(USER));
            return {
                ...state,
                USER: USER
            }

        case 'DO_LOG_OUT':
            localStorage.removeItem('USER');
            cookies.set('ecopoll', false, {path: '/', time: -1});
            return {
                ...state,
                USER: false
            }

        default:
            return state;
    }
}

export default reducer;