import React, {Component} from 'react';
import {Switch, Route, withRouter} from 'react-router-dom';
import CONFIG from '../../SYSTEM/Config.js'
import {createHash, sendPostRequest} from "../HELPERS/requests"

/// REDUX ///
import * as ReduxActions from './../../REDUX/functions.js';
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';

function mapStateToProps(state, myProps) {
    return {
        USER: state.USER
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}


class PollQuestion extends Component {

	constructor(props) {
        super(props);

        this.state = {
            data: props.question,
            show: props.show,
            selected: false
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot)
    {
        if (this.props.show !== prevProps.show) {
        	this.setState({show: this.props.show})
        }
    }

    renderOptionImage = (index) =>
    {
        if (this.state.data.options_images) {
            if (this.state.data.options_images[index] && this.state.data.options_images[index].length) {
               return <img src={ CONFIG.API_C_DOMAIN + this.state.data.options_images[index] } className="option_image" />
            }
        }
    }

    renderQuestionImage = () =>
    {
        if (this.state.data.image) {
            return <img src={ CONFIG.API_C_DOMAIN + this.state.data.image } className="question_image" />
        }
    }

    getCircleClass = (index) =>
    {
        if (this.state.selected) {
            if (this.state.selected == index) {
                return 'selected';
            }
        }

        return '';
    }

    selectOption = (index) =>
    {
        if (!this.state.selected) {
            this.setState({selected: index})
        }
    }

    getOptionClass = (index) =>
    {
        if (this.state.selected) {
            if (this.state.data.correct == index) {
                return ' inactive correct ';
            } else if (this.state.selected == index) {
                return ' inactive incorrect ';
            }
            return ' inactive'
        }

        return '';
    }

    renderNextButton = () =>
    {
        if (this.state.selected) {
            return (
                <div className="text-center">
                    <button className="orange-button" onClick={() => this.props.recordAnswer(this.state.selected)}>
                        К следующему вопросу >>
                    </button>
                </div>
            )
        }
    }

    render()
    {
        if (!this.state.show) {
            return false;
        }

        return (
            <div className="question_holder">
                <div className="question_block">
                    <div className="question_main">
                        {this.renderQuestionImage()}
                        <h3>{this.state.data.question}</h3>
                    </div>
                    <div className="question_answers">
                        <a className={ 'question_option' + this.getOptionClass(1) } onClick={ () => this.selectOption(1) }>
                            {this.renderOptionImage(1)}
                            <div className="option_selecter">
                                <div className={ 'option_circle ' + this.getCircleClass(1) } />
                                <span>{this.state.data.options[0]}</span>
                            </div>
                        </a>
                        <a className={ 'question_option' + this.getOptionClass(2) } onClick={ () => this.selectOption(2) }>
                            {this.renderOptionImage(2)}
                            <div className="option_selecter">
                                <div className={ 'option_circle ' + this.getCircleClass(2) } /> 
                                <span>{this.state.data.options[1]}</span>
                            </div>
                        </a>
                        <a className={ 'question_option' + this.getOptionClass(3) } onClick={ () => this.selectOption(3) }>
                            {this.renderOptionImage(3)}
                            <div className="option_selecter">
                                <div className={ 'option_circle ' + this.getCircleClass(3) } />
                                <span>{this.state.data.options[2]}</span>
                            </div>
                        </a>
                        <a className={ 'question_option' + this.getOptionClass(4) } onClick={ () => this.selectOption(4) }>
                            {this.renderOptionImage(4)}
                            <div className="option_selecter">
                                <div className={ 'option_circle ' + this.getCircleClass(4) } />
                                <span>{this.state.data.options[3]}</span>
                            </div>
                        </a>
                    </div>
                    {this.renderNextButton()}
                </div>
            </div>
        )
    }

}

export default withRouter(connect(
	mapStateToProps,
	mapDispatchToProps
)(PollQuestion))
